import { v4 as uuidv4 } from 'uuid'

import { REQUEST_DENIED_BY_USER_ERROR_CODE } from '../constants'
import { Address, StatefulTransaction, TradeType } from '../model'
import { FormType, sendApmEvent, TxnStatus } from '../services/apmService'

export enum TxActionTypes {
  START_TXN = 'START_TXN',
  RESET_TXN = 'RESET_TXN',
  ERROR_TXN = 'ERROR_TXN',
  CANCEL_TXN = 'CANCEL_TXN',
  UPDATE_TXN = 'UPDATE_TXN',
  TX_VERIFICATION_SUBMITTED = 'TX_VERIFICATION_SUBMITTED',
  TX_VERIFICATION_COMPLETE = 'TX_VERIFICATION_COMPLETE',
}

type TxnAction =
  | TxnStartAction
  | TxnUpdateAction
  | TxnSubmittedAction
  | TxnConfirmedAction
  | TxnResetAction
  | TxnErrorAction
  | TxnCancelAction

export type Patch = Partial<StatefulTransaction> & { lastRequest: string; account?: Address | null }

export interface TxnUpdate {
  key: TradeType
  patch?: Patch
  formType?: FormType
}

export interface TxnStartAction {
  type: TxActionTypes.START_TXN
  payload?: TxnUpdate
}

export interface TxnResetAction {
  type: TxActionTypes.RESET_TXN
  payload?: TxnUpdate
}

export interface TxnErrorAction {
  type: TxActionTypes.ERROR_TXN
  payload?: TxnUpdate
}

export interface TxnCancelAction {
  type: TxActionTypes.CANCEL_TXN
  payload?: TxnUpdate
}

export interface TxnUpdateAction {
  type: TxActionTypes.UPDATE_TXN
  payload?: TxnUpdate
}

export interface TxnSubmittedAction {
  type: TxActionTypes.TX_VERIFICATION_SUBMITTED
  payload?: TxnUpdate
}

export interface TxnConfirmedAction {
  type: TxActionTypes.TX_VERIFICATION_COMPLETE
  payload?: TxnUpdate
}

export interface TxnState {
  Buy: StatefulTransaction
  Sell: StatefulTransaction
}

export const defaultTxnState: TxnState = {
  Buy: {
    estimateLoading: false,
    isLoading: false,
    hashTxn: undefined,
    txnParams: {},
    balanceHasChanged: false,
    type: 'Buy' as TradeType,
    sourcesString: '',
    gasPrice: undefined,
    isEnoughForGas: true,
    approvalInProgress: null,
    txnVerificationComplete: false,
    txnVerificationInProgress: false,
    txnVerificationSuccess: false,
    isNewTxn: true,
    lastRequest: '',
    account: undefined,
    receipt: undefined,
    isPostSubmissionFailure: false,
  },
  Sell: {
    estimateLoading: false,
    isLoading: false,
    hashTxn: undefined,
    txnParams: {},
    balanceHasChanged: false,
    type: 'Sell' as TradeType,
    sourcesString: '',
    gasPrice: undefined,
    isEnoughForGas: true,
    approvalInProgress: null,
    txnVerificationComplete: false,
    txnVerificationInProgress: false,
    txnVerificationSuccess: false,
    isNewTxn: true,
    lastRequest: '',
    account: undefined,
    receipt: undefined,
    isPostSubmissionFailure: false,
  },
}

const txn = (state: TxnState = defaultTxnState, action: TxnAction): TxnState => {
  if (!action.payload) {
    return state
  }

  const { key, patch }: TxnUpdate = action.payload

  let tradeId = state[key]?.tradeId
  if (action.type === TxActionTypes.START_TXN || !tradeId) {
    tradeId = uuidv4()
  }

  const status = deriveStatus(state, action)

  if (status !== 'updated' && status !== 'reset') {
    let patch = action.payload?.patch
    if (patch && status === 'canceled') {
      patch = { ...patch, txnError: { message: '' } }
    }

    sendApmEvent(state[key], status, tradeId, patch, action.payload?.formType)
  }

  switch (action.type) {
    case TxActionTypes.START_TXN: {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...patch,
          isNewTxn: true,
          txnVerificationInProgress: false,
          txnVerificationComplete: false,
          txnVerificationSuccess: false,
          lastRequest: '',
          tradeId,
        },
      }
    }

    case TxActionTypes.RESET_TXN: {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...patch,
          isNewTxn: true,
          txnVerificationInProgress: false,
          txnVerificationComplete: false,
          txnVerificationSuccess: false,
          isPostSubmissionFailure: false,
          lastRequest: '',
          tradeId,
        },
      }
    }

    case TxActionTypes.ERROR_TXN:
    case TxActionTypes.CANCEL_TXN: {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...patch,
          txnVerificationInProgress: false,
          txnVerificationComplete: true,
          txnVerificationSuccess: false,
          lastRequest: patch?.lastRequest || state[key].lastRequest,
          tradeId,
        },
      }
    }

    case TxActionTypes.UPDATE_TXN: {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...patch,
          isNewTxn: true,
          lastRequest: patch?.lastRequest || state[key].lastRequest,
          tradeId,
        },
      }
    }

    case TxActionTypes.TX_VERIFICATION_SUBMITTED: {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...patch,
          isNewTxn: false,
          txnVerificationInProgress: true,
          txnVerificationComplete: false,
          txnVerificationSuccess: false,
          lastRequest: patch?.lastRequest || state[key].lastRequest,
          tradeId,
        },
      }
    }

    case TxActionTypes.TX_VERIFICATION_COMPLETE: {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...patch,
          isNewTxn: false,
          txnVerificationInProgress: false,
          txnVerificationComplete: true,
          txnVerificationSuccess: true,
          lastRequest: patch?.lastRequest || state[key].lastRequest,
          tradeId,
        },
      }
    }
    default:
      return state
  }
}

const deriveStatus = (state: TxnState, action: TxnAction): TxnStatus => {
  if (action.payload?.patch?.txnError?.message === `${REQUEST_DENIED_BY_USER_ERROR_CODE}`) {
    return 'canceled'
  }
  if (action.payload?.patch?.txnError?.message || action.payload?.patch?.error) {
    return 'error'
  }

  switch (action.type) {
    case TxActionTypes.TX_VERIFICATION_SUBMITTED:
      return 'submitted'
    case TxActionTypes.TX_VERIFICATION_COMPLETE:
      return 'success'
    case TxActionTypes.START_TXN:
      return 'started'
    case TxActionTypes.RESET_TXN:
      return 'reset'
    case TxActionTypes.UPDATE_TXN:
    default:
      return 'updated'
  }
}

export default txn
