import { BalanceData } from '../services/ApiClient/WalletsApi'

export enum WalletActions {
  startLoadWalletTokens = 'START_LOAD_WALLET_TOKENS',
  successLoadWalletTokens = 'SUCCESS_LOAD_WALLET_TOKENS',
  failLoadWalletTokens = 'FAIL_LOAD_WALLET_TOKENS',
}

enum WalletErrors {
  failLoading = 'failLoading',
}

export type StartLoadWalletTokensAction = {
  type: WalletActions.startLoadWalletTokens
}

export type SuccessLoadWalletTokensAction = {
  type: WalletActions.successLoadWalletTokens
  payload: BalanceData
}

export type FailLoadWalletTokensAction = {
  type: WalletActions.failLoadWalletTokens
  payload: string | WalletErrors
}

export type WalletAction =
  | StartLoadWalletTokensAction
  | SuccessLoadWalletTokensAction
  | FailLoadWalletTokensAction

export type WalletState = {
  isLoading: boolean
  balanceData?: BalanceData
  error?: string
}

export const defaultWalletState: WalletState = {
  isLoading: false,
}

const walletReducer = (
  state: WalletState = defaultWalletState,
  action: WalletAction
): WalletState => {
  switch (action.type) {
    case WalletActions.startLoadWalletTokens:
      return { ...state, isLoading: true }
    case WalletActions.successLoadWalletTokens:
      return { ...state, balanceData: action.payload, isLoading: false }
    case WalletActions.failLoadWalletTokens:
      return {
        ...defaultWalletState,
        error: action.payload,
      }
    default:
      return state
  }
}

export default walletReducer
