import { SidebarType } from '../components/Sidebar/sidebarUtils'
import { TradeformType, TradeType } from '../model'

export enum UIActions {
  setTokenProfileVisibilityFlag = 'SET_TOKEN_PROFILE_VISIBILITY_FLAG',
  setOmniboxSearchOpen = 'SET_OMNIBOX_SEARCH_OPEN',
  setHeaderFavoriteAnimationActive = 'SET_HEADER_FAVORITE_ANIMATION_ACTIVE',
  setDropsearchFavoriteAnimationActive = 'SET_DROPSEARCH_FAVORITE_ANIMATION_ACTIVE',
  setSidebarOpen = 'SET_SIDERBAR_OPEN',
  setTradeformType = 'SET_TRADEFORM_TYPE',
  setSettingsOpen = 'SET_SETTINGS_OPEN',
  setOverviewOpen = 'SET_OVERVIEW_OPEN',
  setDataDisclaimerOpen = 'SET_DATA_DISCLAIMER_OPEN',
  setNotificationsOpen = 'SET_NOTIFICATIONS_OPEN',
  setOpenModalVerify = 'SET_MODAL_VERIFY_OPEN',
  setSeasonPassOpen = 'SET_SEASON_PASS_OPEN',
  setTraderProfileOpen = 'SET_TRADER_PROFILE_OPEN',
  setProviderMenuOpen = 'SET_PROVIDER_MENU_OPEN',
}

export type SetTraderProfileOpen = {
  type: UIActions.setTraderProfileOpen
  payload: boolean
}

export type SetTokenProfileVisibilityFlag = {
  type: UIActions.setTokenProfileVisibilityFlag
  payload: boolean
}

export type SetOmniboxSearchOpen = {
  type: UIActions.setOmniboxSearchOpen
  payload: { value: boolean; openedIndirectly?: boolean }
}

export type SetHeaderFavoriteAnimationActive = {
  type: UIActions.setHeaderFavoriteAnimationActive
  payload: boolean
}

export type SetDropsearchFavoriteAnimationActive = {
  type: UIActions.setDropsearchFavoriteAnimationActive
  payload: boolean
}

export type SetOpenSidebar = {
  type: UIActions.setSidebarOpen
  payload: SidebarType
}

export type SetTradeformType = {
  type: UIActions.setTradeformType
  payload: TradeformType
}

export type SetSettingsOpen = {
  type: UIActions.setSettingsOpen
  payload: boolean
}

export type SetOverviewOpen = {
  type: UIActions.setOverviewOpen
  payload: boolean
}

export type SetDataDisclaimerOpen = {
  type: UIActions.setDataDisclaimerOpen
  payload: boolean
}

export type SetNotificationsOpen = {
  type: UIActions.setNotificationsOpen
  payload: boolean
}

export type SetOpenModalVerify = {
  type: UIActions.setOpenModalVerify
  payload: TradeType | undefined
}

export type SetSeasonPassOpen = {
  type: UIActions.setSeasonPassOpen
  payload: boolean
}

export type SetProviderMenuOpen = {
  type: UIActions.setProviderMenuOpen
  payload: boolean
}

type UIAction =
  | SetTraderProfileOpen
  | SetTokenProfileVisibilityFlag
  | SetOmniboxSearchOpen
  | SetHeaderFavoriteAnimationActive
  | SetDropsearchFavoriteAnimationActive
  | SetOpenSidebar
  | SetTradeformType
  | SetSettingsOpen
  | SetOverviewOpen
  | SetDataDisclaimerOpen
  | SetNotificationsOpen
  | SetOpenModalVerify
  | SetSeasonPassOpen
  | SetProviderMenuOpen

export type UIState = {
  isTokenProfileOpen: boolean
  isTraderProfileOpened: boolean
  isOmniboxSearchOpen: boolean
  wasOmniboxOpenedDirectly: boolean
  isHeaderFavoriteAnimationActive: boolean
  isDropsearchFavoriteAnimationActive: boolean
  isSidebarOpen: SidebarType
  isSettingsOpen: boolean
  isOverviewOpen: boolean
  isDataDisclaimerOpen: boolean
  isNotificationsOpen: boolean
  modalVerifyOpen?: TradeType
  seasonPassOpen: boolean
  hasProviderMenuBeenOpened: boolean
  isProviderMenuOpen: boolean
  tradeformType: TradeformType
}

export const defaultUIState: UIState = {
  isTokenProfileOpen: false,
  isTraderProfileOpened: false,
  isOmniboxSearchOpen: false,
  wasOmniboxOpenedDirectly: false,
  isHeaderFavoriteAnimationActive: false,
  isDropsearchFavoriteAnimationActive: false,
  // if used SidebarType.none
  // src/__tests__/Settings.test.tsx
  // TypeError: Cannot read properties of undefined (reading 'none')
  isSidebarOpen: 'none' as SidebarType,
  isSettingsOpen: false,
  isOverviewOpen: false,
  isDataDisclaimerOpen: false,
  isNotificationsOpen: false,
  modalVerifyOpen: undefined,
  seasonPassOpen: false,
  // TODO https://app.shortcut.com/dexguru/story/20970/conditional-injection-of-web3-react set to false when complete
  hasProviderMenuBeenOpened: true,
  isProviderMenuOpen: false,
  tradeformType: 'market',
}

const uiReducer = (state: UIState = defaultUIState, action: UIAction): UIState => {
  switch (action.type) {
    case UIActions.setTraderProfileOpen:
      return { ...state, isTraderProfileOpened: action.payload }
    case UIActions.setTokenProfileVisibilityFlag:
      return { ...state, isTokenProfileOpen: action.payload }
    case UIActions.setOmniboxSearchOpen:
      return {
        ...state,
        isOmniboxSearchOpen: action.payload.value,
        wasOmniboxOpenedDirectly: !action.payload.openedIndirectly,
      }
    case UIActions.setHeaderFavoriteAnimationActive:
      return { ...state, isHeaderFavoriteAnimationActive: action.payload }
    case UIActions.setDropsearchFavoriteAnimationActive:
      return { ...state, isDropsearchFavoriteAnimationActive: action.payload }
    case UIActions.setSidebarOpen:
      return { ...state, isSidebarOpen: action.payload }
    case UIActions.setTradeformType:
      return { ...state, tradeformType: action.payload }
    case UIActions.setSettingsOpen:
      return { ...state, isSettingsOpen: action.payload }
    case UIActions.setOverviewOpen:
      return { ...state, isOverviewOpen: action.payload }
    case UIActions.setDataDisclaimerOpen:
      return { ...state, isDataDisclaimerOpen: action.payload }
    case UIActions.setNotificationsOpen:
      return { ...state, isNotificationsOpen: action.payload }
    case UIActions.setOpenModalVerify:
      return { ...state, modalVerifyOpen: action.payload }
    case UIActions.setSeasonPassOpen:
      return { ...state, seasonPassOpen: action.payload }
    case UIActions.setProviderMenuOpen:
      return { ...state, hasProviderMenuBeenOpened: true, isProviderMenuOpen: action.payload }
    default:
      return state
  }
}

export default uiReducer
