import { model } from '@telekomconsalting/dex-guru-model'

import { UserWalletCategory } from '../model'

export enum UserActions {
  setWalletCategory = 'SET_WALLET_CATEGORY',
  setWalletAddress = 'SET_WALLET_ADDRESS',
}

export interface SetWalletAddressAction {
  type: UserActions.setWalletAddress
  payload: {
    address?: model.Address | null
    provider?: string
  }
}

export interface SetWalletCategoryAction {
  type: UserActions.setWalletCategory
  walletCategory?: UserWalletCategory
}

export type UserAction = SetWalletCategoryAction | SetWalletAddressAction

type UserState = {
  walletCategory?: UserWalletCategory
  walletProvider?: string
  address?: model.Address | null
}

export const defaultState: UserState = {
  walletCategory: undefined,
  walletProvider: undefined,
  address: undefined,
}

const userReducer = (state: UserState = defaultState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActions.setWalletCategory:
      return { ...state, walletCategory: action.walletCategory }
    case UserActions.setWalletAddress:
      return {
        ...state,
        address: action.payload?.address,
        walletProvider: action.payload?.provider,
      }
    default:
      return state
  }
}

export default userReducer
